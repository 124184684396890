//
// Menu
//

.docs-aside-menu {
    .menu {
        @include menu-link-padding-x(get($docs-aside-config, padding-x));
        @include menu-link-indention(get($docs-aside-config, menu-indention), get($docs-aside-config, padding-x));
    }

    .menu-item {
        padding: 0;

        .menu-link {
            font-weight: 500;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }

        .menu-icon {
            justify-content: flex-start;
        }
    }
}
