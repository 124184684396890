#container-aside-primary-scroll {
  height: calc(100%);
}

#container-aside-secondary-scroll {
  height: calc(100% - 65px);
}

#container-search-form {
  position: relative;
  z-index: 115;
}

#container-search {
  position: fixed;
  inset: 0px auto auto 0px;
  margin: 0px;

  @media only screen and (max-width: 992px) {
    transform: translate3d(85px, 58px, 0px);
  }

  @media only screen and (min-width: 992px) {
    transform: translate(116px, 61px);
  }
}

#container-category-listing {
  max-height: calc(100vh - 500px);
}

#dropdown-notifications {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(28px, -108px, 0px);
  height: 500px;

  #background-dropdown-header {
    background-image: url(../assets/images/themes/image-background.png);
  }
}

#dropdown-languages {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(28px, -108px, 0px);

  #background-dropdown-header {
    background-image: url(../assets/images/themes/image-background.png);
  }
}

#dropdown-user-profile {
  position: fixed;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(29px, -55px);
}

#modal-contact-update-container {
  height: auto;
  max-height: 55vh;
  min-height: 55vh;
}

#modal-voucher-view-container {
  height: 85vh;
  width: auto;
}
