//
// Header
//

@include media-breakpoint-up(lg) {
	.docs-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@include media-breakpoint-down(lg) {
	.docs-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.docs-page-title[data-kt-swapper="true"]{
			display: none !important;
		}
	}
}
