//
// Toggle
//

.toggle {
    &.collapsible:not(.collapsed),
    &.active {
        .toggle-off {
            display: none;
        }
    }

    &.collapsible.collapsed,
    &:not(.collapsible):not(.active) {
        .toggle-on {
            display: none;
        }
    }
}
