//
// Root CSS Variables
//

:root {
    @each $name, $value in $theme-colors {
        @if ( theme-light-color($name) ) {
            --#{$variable-prefix}light-#{$name}:#{theme-light-color($name)};
            --#{$variable-prefix}active-#{$name}:#{theme-active-color($name)};
        }
    }

    @each $name, $value in $grays {
        --#{$variable-prefix}gray-#{$name}:#{$value};
    }

    @each $breakpoint, $value in $grid-breakpoints {
        --#{$variable-prefix}#{$breakpoint}:#{$value};
    }
}
