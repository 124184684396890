//
// Main
//

.docs-wrapper {
	background-color: $docs-page-bg-color;
}

@include media-breakpoint-up(lg) {
	.container,
	.container-fluid {
		padding: 0 get($docs-content-spacing, desktop);
	}

	.docs-wrapper {
		padding-left: get($docs-aside-config, width);
	}
}

@include media-breakpoint-down(lg) {
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($docs-content-spacing, tablet-and-mobile);
	}
}
