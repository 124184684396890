//
// Page loader
//

.page-loading * {
    transition: none !important;
}

.page-loader {
	background: $body-bg;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
  display: none;

  .page-loading & {
		display: flex;
		justify-content: center;
  	align-items: center;
	}
}

.page-preloader {
	height: calc(100vh - 200px);

	@include media-breakpoint-down(lg) {
		height: calc(100vh - 250px);
}
}