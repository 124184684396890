//
// Fullcalendar plugin customization: https://fullcalendar.io/
//

body {    
    --fc-event-border-color: #{$primary};
    --fc-event-bg-color: #{$primary};
    --fc-event-text-color: #{$primary-inverse};
}

.fc {
    --fc-border-color: #{$gray-200};
    --fc-page-bg-color: #{$white};
    --fc-small-font-size: 0.95rem;

    --fc-highlight-color: #{$lighten};

    --fc-bg-event-opacity: 0.3;
    --fc-neutral-bg-color: #{$light};

    --fc-today-bg-color: #{if(isDarkMode(), $primary-light, $warning-light)};

    --fc-now-indicator-color: #{$danger};
    --fc-list-event-hover-bg-color: #{$lighten};

    --fc-button-text-color: #{$gray-600};
    --fc-button-bg-color: #{$gray-100};
    --fc-button-border-color: #{$gray-100};
    
    --fc-button-hover-bg-color:  #{$gray-100};
    --fc-button-hover-border-color:  #{$gray-100};

    --fc-button-active-bg-color: #{$gray-200};
    --fc-button-active-border-color: #{$gray-200};

    table {
        font-size: 1rem;
    }     

    .fc-button { 
        padding: 0.75rem 1.25rem;
        box-shadow: none !important;
        border: 0 !important;
        border-radius: $btn-border-radius;
        vertical-align: middle;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
    }

    .fc-button-primary {
        margin: 0;

        .fc-icon {
            font-size: 1.35rem;
            margin-bottom: 0.15rem;
        }

        &:not(:disabled):not(.fc-button-active) {
            &:focus,
            &:hover,
            &:active {
                color: $gray-900;

                .fc-icon {
                    color: $gray-900;
                }
            }
        }
        
        &:not(:disabled).fc-button-active {
            color: $gray-900;

            .fc-icon {
                color: $gray-900;
            }
        }
    }

    .fc-button-group {
        .fc-button {
            margin: 0 !important;
        }
    }

    .fc-toolbar-title {
        font-size: 1.5rem;
        font-weight: $font-weight-bolder;
        color: $gray-800;
    }

    .fc-col-header-cell {
        padding: 0.75rem 0.5rem;

        .fc-col-header-cell-cushion  {            
            font-size: 1.1rem;
            font-weight: $font-weight-bold;
            color: $gray-800;
        }
    }    

    .fc-scrollgrid {
        @include border-radius($border-radius);

        thead > tr {
            td:first-child {
                @include border-top-start-radius($border-radius);
            }

            td:last-child {
                @include border-top-end-radius($border-radius);
            }
        }

        tbody > tr:last-child {
            td:first-child {
                @include border-bottom-start-radius($border-radius);
            }

            td:last-child {
                @include border-bottom-end-radius($border-radius);
            }
        }
    }

    .fc-daygrid-event {
        margin-top: 3px;
    }

    .fc-daygrid-dot-event,
    .fc-daygrid-block-event {
        .fc-event-title,
        .fc-event-time {
            padding: 0.25rem 0.25rem;
        }
    }

    .fc-daygrid-day-number {
        color: $gray-800;
    }

    .fc-daygrid-dot-event {
        background-color: $light;
        color: $gray-600;

        .fc-event-title {
            font-weight: $font-weight-bold;
        }

        &:hover,
        &.fc-event-mirror {
            background-color: $light;
            color: $primary;
        }
    }

    .fc-daygrid-event-dot {
        margin-left: 0.5rem;
        margin-right: 0.1rem;
    }        

    .fc-popover {
        border: 0 !important;
        background-color: $tooltip-bg;
        box-shadow: $dropdown-box-shadow;
        @include border-radius($dropdown-border-radius);

        .fc-popover-header {
            @include border-top-radius($dropdown-border-radius);
            padding: 0.65rem 0.75rem;
            background-color: $tooltip-bg;
            
            .fc-popover-title {
                color: $gray-800;
                font-size: 1rem;
                font-weight: $font-weight-bold;
            }

            .fc-popover-close {
                font-size: 1rem;
                color: $gray-600;

                &:hover {
                    color: $primary;
                }
            }
        }

        .fc-popover-body  {
            padding: 0.5rem 0.75rem 0.75rem 0.75rem;
        }
    }

    .fc-daygrid-more-link {
        font-weight: $font-weight-bold;
    }

    .fc-timegrid-slot {
        height: 2rem;
        font-size: 0.95rem;
    }

    .fc-list-day-cushion, 
    .fc-list-table td {
        padding: 0.85rem 1.15rem;
    }

    .fc-list-day-text,
    .fc-list-day-side-text {
        font-size: 1.1rem;
        color: $gray-900;
        font-weight: $font-weight-bolder;
    }

    .fc-list,
    .fc-scroller,
    .fc-list-table {
        @include border-radius($border-radius);
    }

    .fc-list {
        overflow: hidden;
        position: relative;
    }

    .fc-timegrid-axis {
        padding-left: 0rem;
        padding-right: 0;
    }

    .fc-timegrid-event .fc-event-main {
        padding: 0.25rem 0.25rem;
    }

    .fc-timegrid-now-indicator-arrow {
        margin-top: -1px;
    }
}

.fc-h-event {
    font-weight: $font-weight-normal;
}

@include media-breakpoint-down(md) {
    .fc {
        .fc-header-toolbar {   
            flex-direction: column;
            align-items: flex-start;

            .fc-toolbar-chunk {
                &:nth-child(1),
                &:nth-child(3) {
                    order: 2;
                }

                &:nth-child(2) {
                    order: 1;
                }

                &:nth-child(2),
                &:nth-child(1) {
                    margin-bottom: 1rem;
                } 
            }            
        }
    }
}
