//
// Dropzone plugin customization:  https://www.dropzonejs.com
//

.dropzone {
    min-height: auto;
    padding: 1rem 1.5rem;
	text-align: center;
	cursor: pointer;
    border: 1px dashed $primary;
    background-color: $primary-light;
    border-radius: $border-radius !important;

    .dz-message{
        margin: 0;
        display: flex;
        text-align: left;
    }

    .dz-preview {
        border-radius: $border-radius !important;
        margin: 13px;

		.dz-image {
			border-radius: $border-radius !important;
            z-index: 1;
		}

        &.dz-file-preview {
            .dz-image {
                background: $gray-100;
            }
        }
    }

    .dz-success-mark,
    .dz-error-mark {
        $size: 40px;

        margin-left: -(divide($size, 2)) !important;
        margin-top: -(divide($size, 2)) !important;

        svg {
            height: $size !important;
            width: $size !important;
        }
    }

    .dz-remove {
        $size: 1.8rem;
        display:flex;
		justify-content: center;
		align-items: center;
		height: $size;
		width: $size;
		font-size: 1rem;
        text-indent: -9999px;
        white-space: nowrap;  
        position: absolute;
        z-index: 30;
        background-size: 40%;
        background-color: $white !important;
        box-shadow: $box-shadow-sm;        
		border-radius: 100%;
        top: -(divide($size,2));
        right: -(divide($size,2));

        @include svg-bg-icon(close, $gray-700);

        &:hover {
            @include svg-bg-icon(close, $primary);
        }
    }

    .dz-error-message {
        color: $danger-inverse;
        background: $danger;
    }
}

.dropzone.dropzone-queue {
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;
    cursor: auto !important;

    .dz-message{
        display: none;
    }

    .dropzone-panel {
        .dropzone-upload,
        .dropzone-remove-all{
            display: none;
        }
    }

    .dropzone-item {
        display: flex;
        align-items: center;
        margin-top: 0.75rem;
        @include border-radius($border-radius);
        padding: 0.5rem 1rem;
        background-color: $gray-100;

        .dropzone-file {
            flex-grow: 1;

            .dropzone-filename {
                font-size: 0.9rem;
                font-weight: 500;
                color: $gray-600;
                text-overflow: ellipsis;
                margin-right: 0.5rem;

                &:hover {
                    color: $primary;
                }
            }

            .dropzone-error {
                margin-top: 0.25rem;
                font-size: 0.9rem;
                font-weight: 400;
                color: $danger;
                text-overflow: ellipsis;
            }
        }

        .dropzone-progress {
            width: 15%;

            .progress {
                height: 5px;
                @include transition;
            }
        }

        .dropzone-toolbar {
            margin-left: 1rem;
            display: flex;
            flex-wrap: nowrap;

            .dropzone-start,
            .dropzone-cancel,
            .dropzone-delete {
                height: 25px;
                width: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: $transition-link;

                i {
                    transition: $transition-link;
                    font-size: 0.8rem;
                    color: $gray-600;
                }

                &:hover {
                    transition: $transition-link;
                    i {
                        color: $primary;
                    }
                }
            }

            .dropzone-start{
                transition: $transition-link;
            }
        }
    }
}

//
// Dropzone plugin customization:  plugings.bundle.css
//

.dropzone.dz-clickable {
    cursor: pointer
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 13px;
    min-height: 100px;
    cursor: default;
}

.dropzone .dz-preview:hover {
    z-index: 100
}

.dropzone .dz-preview.dz-image-preview {
    background: #000
}

.dropzone .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10
}

.dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05,1.05);
    -moz-transform: scale(1.05,1.05);
    -ms-transform: scale(1.05,1.05);
    -o-transform: scale(1.05,1.05);
    transform: scale(1.05,1.05);
    -webkit-filter: blur(8px);
    filter: blur(3px)
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition:opacity .2s linear;
    -moz-transition:opacity .2s linear;
    -ms-transition:opacity .2s linear;
    -o-transition:opacity .2s linear;
    transition:opacity .2s linear
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 11px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0,0,0,.9);
    line-height: 150%
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 0.5em;
    font-size: 13px
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
    font-weight: bolder
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255,255,255,.4);
    padding: 0 .4em;
    border-radius: 3px
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
    border-radius: 8px;
    font-size: 10px;
    top: 110px;
    left: -10px;
    width: 140px;
    background: linear-gradient(to bottom,#be2626,#a92222);
    padding: .5em 1em;
    color: #fff;
    font-weight: 500;
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto
}

.dropzone .dz-error-mark,
.dropzone .dz-success-mark {
    margin-left: -20px !important;
    margin-top: -20px !important
}

.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 70%;
    left: 50%;
}

.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(.77,0,.175,1);
    -moz-animation: slide-in 3s cubic-bezier(.77,0,.175,1);
    -ms-animation: slide-in 3s cubic-bezier(.77,0,.175,1);
    -o-animation: slide-in 3s cubic-bezier(.77,0,.175,1);
    animation: slide-in 3s cubic-bezier(.77,0,.175,1)
}

.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg {
    display: block;
    width: 55px;
    height: 55px
}

.dropzone .dz-error-mark svg,
.dropzone .dz-success-mark svg {
    height: 40px!important;
    width: 40px!important
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline
}