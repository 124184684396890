//
// Aside extended
//

.aside {
	display: flex;
	box-shadow: get($aside-config, box-shadow);

	.btn-custom {
		$active-bg-color: if(isDarkMode(), lighten(get($aside-config, bg-color, primary), 2%), #0B1632);

		@include button-custom-variant($color:null, $icon-color: #A2A7B4, $border-color:null, $bg-color:null, $color-active:$white, $icon-color-active:$white, $border-color-active: $active-bg-color, $bg-color-active: $active-bg-color);
	}
	
	.aside-primary {
		flex-shrink: 0;
		background-color: get($aside-config, bg-color, primary);

		.aside-secondary-enabled & {
			border-right: 1px solid transparent;
		}

		.aside-secondary-enabled:not([data-kt-aside-minimize="on"]) & {
			@if (isDefaultMode()) {
				border-right-color: $border-color;
			}			
		}
	}

	.aside-secondary {
		overflow: hidden;
		background-color: get($aside-config, bg-color, secondary);
		
		.aside-workspace {
			width: get($aside-config, width, desktop) - get($aside-config, primary-width, desktop);
			flex-shrink: 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	.aside {
		transition: width get($aside-config, transition-speed) ease;

		.aside-primary {
			width: get($aside-config, primary-width, desktop);
		}

		.aside-secondary {			
			.aside-workspace {
				width: get($aside-config, width, desktop) - get($aside-config, primary-width, desktop);
				flex-shrink: 0;
			}
		}

		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: get($aside-config, z-index);
		}

		.aside-static & {
			position: relative;
			z-index: 1;
		}

		.aside-secondary-enabled & {
			width: get($aside-config, width, desktop);
		}

		.aside-secondary-disabled & {
			width: get($aside-config, primary-width, desktop);

			.aside-primary {
				border-right: 0;
			}
		}

		[data-kt-aside-minimize="on"] & {
			width: get($aside-config, primary-width, desktop);
			transition: width get($aside-config, transition-speed) ease;
		}
	}
}

@include media-breakpoint-down(lg) {
	.aside {
		display: none;
		width: get($aside-config, width, tablet-and-mobile);

		.aside-primary {
			width: get($aside-config, primary-width, tablet-and-mobile);
		}

		.aside-secondary {			
			.aside-workspace {
				width: get($aside-config, width, tablet-and-mobile) - get($aside-config, primary-width, tablet-and-mobile);
				flex-shrink: 0;
			}
		}

		.aside-secondary-enabled & {
			width: get($aside-config, width, tablet-and-mobile);
		}

		.aside-secondary-disabled & {
			width: get($aside-config, primary-width, tablet-and-mobile);

			.aside-primary {
				border-right: 0;
			}
		}
	}
}
