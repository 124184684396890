//
// Input placeholder color
//

@mixin placeholder($color) {
    &::placeholder { 
        color: $color;
    }
    
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }
}
