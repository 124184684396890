//
// Menu Theme
//

@each $name, $color in $theme-text-colors {
    .menu-#{$name} {
        .menu-item {
            /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
            @include menu-link-default-state( $color, $color, $color, $color, null );
        }
    }

    .menu-title-#{$name} {
        .menu-item {
            /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
            @include menu-link-default-state( $color, null, null, null, null );
        }
    }

    .menu-icon-#{$name} {
        .menu-item {
            /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
            @include menu-link-default-state( null, $color, null, null, null );
        }
    }

    .menu-bullet-#{$name} {
        .menu-item {
            /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
            @include menu-link-default-state( null, null, $color, null, null );
        }
    }

    .menu-arrow-#{$name} {
        .menu-item {
            /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
            @include menu-link-default-state( null, null, null, $color, null );
        }
    }
}

.menu-hover-bg {
    .menu-item {
        @include menu-link-hover-state( null, null, null, null, get($menu, link, self, bg-color, hover) );
    }
}

.menu-show-bg {
    .menu-item {
        @include menu-link-show-state( null, null, null, null, get($menu, link, self, bg-color, show) );
    }
}

.menu-active-bg {
    .menu-item {
        @include menu-link-active-state( null, null, null, null, get($menu, link, self, bg-color, active) );
    }
}

.menu-state-bg {
    .menu-item {
        @include menu-link-hover-state( null, null, null, null, get($menu, link, self, bg-color, hover) );
        @include menu-link-show-state( null, null, null, null, get($menu, link, self, bg-color, show) );
        @include menu-link-active-state( null, null, null, null, get($menu, link, self, bg-color, active) );
    }
}

.menu-hover-primary {
    .menu-item {
        @include menu-link-hover-state( $primary, $primary, $primary, $primary, null );
    }
}

.menu-show-primary {
    .menu-item {
        @include menu-link-show-state( $primary, $primary, $primary, $primary, null );
    }
}

.menu-active-primary {
    .menu-item {
        @include menu-link-active-state( $primary, $primary, $primary, $primary, null );
    }
}

.menu-state-primary {
    .menu-item {
        @include menu-link-hover-state( $primary, $primary, $primary, $primary, null );
        @include menu-link-show-state( $primary, $primary, $primary, $primary, null );
        @include menu-link-active-state( $primary, $primary, $primary, $primary, null );
    }
}

.menu-hover-bg-primary {
    .menu-item {
        @include menu-link-hover-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
    }
}

.menu-show-bg-primary {
    .menu-item {
        @include menu-link-show-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
    }
}

.menu-active-bg-primary {
    .menu-item {
        @include menu-link-active-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
    }
}

.menu-state-bg-primary {
    .menu-item {
        @include menu-link-hover-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
        @include menu-link-show-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
        @include menu-link-active-state( $primary-inverse, $primary-inverse, $primary-inverse, $primary-inverse, $primary );
    }
}

.menu-show-bg-light-primary {
    .menu-item {
        @include menu-link-show-state( $primary, $primary, $primary, $primary, $primary-light );
    }
}

.menu-hover-bg-light-primary {
    .menu-item {
        @include menu-link-hover-state( $primary, $primary, $primary, $primary, $primary-light );
    }
}

.menu-active-bg-light-primary {
    .menu-item {
        @include menu-link-active-state( $primary, $primary, $primary, $primary, $primary-light );
    }
}

.menu-state-bg-light-primary {
    .menu-item {
        @include menu-link-show-state( $primary, $primary, $primary, $primary, $primary-light );
        @include menu-link-hover-state( $primary, $primary, $primary, $primary, $primary-light );
        @include menu-link-active-state( $primary, $primary, $primary, $primary, $primary-light );
    }
}

.menu-hover-title-primary {
    .menu-item {
        @include menu-link-hover-state( $primary, null, null, null, null );
    }
}

.menu-show-title-primary {
    .menu-item {
        @include menu-link-show-state( $primary, null, null, null, null );
    }
}

.menu-active-title-primary {
    .menu-item {
        @include menu-link-active-state( $primary, null, null, null, null );
    }
}

.menu-state-title-primary {
    .menu-item {
        @include menu-link-hover-state( $primary, null, null, null, null );
        @include menu-link-show-state( $primary, null, null, null, null );
        @include menu-link-active-state( $primary, null, null, null, null );
    }
}

.menu-hover-icon-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, $primary, null, null, null );
    }
}

.menu-show-icon-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-show-state( null, $primary, null, null, null );
    }
}

.menu-active-icon-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-active-state( null, $primary, null, null, null );
    }
}

.menu-state-icon-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, $primary, null, null, null );
        @include menu-link-show-state( null, $primary, null, null, null );
        @include menu-link-active-state( null, $primary, null, null, null );
    }
}

.menu-hover-bullet-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, null, $primary, null, null );
    }
}

.menu-show-bullet-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-show-state( null, null, $primary, null, null );
    }
}

.menu-active-bullet-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-active-state( null, null, $primary, null, null );
    }
}

.menu-state-bullet-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, null, $primary, null, null );
        @include menu-link-show-state( null, null, $primary, null, null );
        @include menu-link-active-state( null, null, $primary, null, null );
    }
}

.menu-hover-arrow-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, null, null, $primary, null );
    }
}

.menu-show-arrow-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-show-state( null, null, null, $primary, null );
    }
}

.menu-active-arrow-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-active-state( null, null, null, $primary, null );
    }
}

.menu-state-arrow-primary {
    .menu-item {
        /*$title-color,$icon-color,$bullet-color,$arrow-color,$bg-color*/
        @include menu-link-hover-state( null, null, null, $primary, null );
        @include menu-link-show-state( null, null, null, $primary, null );
        @include menu-link-active-state( null, null, null, $primary, null );
    }
}

.menu-state-bg-light-info {
    .menu-item {
        @include menu-link-show-state( $info, $info, $info, $info, $info-light );
        @include menu-link-hover-state( $info, $info, $info, $info, $info-light );
        @include menu-link-active-state( $info, $info, $info, $info, $info-light );
    }
}
