@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(assets/fonts/poppins.css);

@keyframes trans1 { 
  from { 
    transform: translate3d(53px, 0, 0);
  }
  to { 
    transform: translate3d(-250px, 0, 0);
  }
}

@keyframes trans2 { 
  from { 
    transform: translate3d(-160px, 0, 0);
  }
  to { 
    transform: translate3d(53px, 0, 0);
  }
}

@keyframes trans3 { 
  from { 
    transform: translate3d(53px, 0, 0);
  }
  to { 
    transform: translate3d(-220px, 0, 0);
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fly1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-204deg) rotateY(-338deg) rotateZ(389deg) translateZ(-608px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-204deg) rotateY(-338deg) rotateZ(389deg) translateZ(-608px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-204deg) rotateY(-338deg) rotateZ(389deg) translateZ(-608px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-204deg) rotateY(-338deg) rotateZ(389deg) translateZ(-608px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-21deg) rotateY(-197deg) rotateZ(-472deg) translateZ(-1300px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-21deg) rotateY(-197deg) rotateZ(-472deg) translateZ(-1300px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-21deg) rotateY(-197deg) rotateZ(-472deg) translateZ(-1300px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-21deg) rotateY(-197deg) rotateZ(-472deg) translateZ(-1300px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly3 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(443deg) rotateY(359deg) rotateZ(-302deg) translateZ(-2491px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(443deg) rotateY(359deg) rotateZ(-302deg) translateZ(-2491px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly3 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(443deg) rotateY(359deg) rotateZ(-302deg) translateZ(-2491px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(443deg) rotateY(359deg) rotateZ(-302deg) translateZ(-2491px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly4 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-70deg) rotateY(448deg) rotateZ(-190deg) translateZ(-836px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-70deg) rotateY(448deg) rotateZ(-190deg) translateZ(-836px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly4 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-70deg) rotateY(448deg) rotateZ(-190deg) translateZ(-836px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-70deg) rotateY(448deg) rotateZ(-190deg) translateZ(-836px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly5 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-66deg) rotateZ(-179deg) translateZ(274px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-66deg) rotateZ(-179deg) translateZ(274px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly5 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-66deg) rotateZ(-179deg) translateZ(274px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-66deg) rotateZ(-179deg) translateZ(274px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly6 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-333deg) rotateY(-150deg) rotateZ(173deg) translateZ(-110px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-333deg) rotateY(-150deg) rotateZ(173deg) translateZ(-110px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly6 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-333deg) rotateY(-150deg) rotateZ(173deg) translateZ(-110px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-333deg) rotateY(-150deg) rotateZ(173deg) translateZ(-110px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly7 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-111deg) rotateY(-412deg) rotateZ(-138deg) translateZ(-2168px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-111deg) rotateY(-412deg) rotateZ(-138deg) translateZ(-2168px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly7 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-111deg) rotateY(-412deg) rotateZ(-138deg) translateZ(-2168px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-111deg) rotateY(-412deg) rotateZ(-138deg) translateZ(-2168px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly8 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(483deg) rotateY(-366deg) rotateZ(408deg) translateZ(206px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(483deg) rotateY(-366deg) rotateZ(408deg) translateZ(206px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly8 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(483deg) rotateY(-366deg) rotateZ(408deg) translateZ(206px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(483deg) rotateY(-366deg) rotateZ(408deg) translateZ(206px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly9 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(43deg) rotateY(395deg) rotateZ(-368deg) translateZ(-207px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(43deg) rotateY(395deg) rotateZ(-368deg) translateZ(-207px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly9 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(43deg) rotateY(395deg) rotateZ(-368deg) translateZ(-207px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(43deg) rotateY(395deg) rotateZ(-368deg) translateZ(-207px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly10 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-164deg) rotateY(363deg) rotateZ(-130deg) translateZ(-2309px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-164deg) rotateY(363deg) rotateZ(-130deg) translateZ(-2309px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly10 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-164deg) rotateY(363deg) rotateZ(-130deg) translateZ(-2309px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-164deg) rotateY(363deg) rotateZ(-130deg) translateZ(-2309px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly11 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-141deg) rotateY(192deg) rotateZ(157deg) translateZ(-2211px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-141deg) rotateY(192deg) rotateZ(157deg) translateZ(-2211px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly11 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-141deg) rotateY(192deg) rotateZ(157deg) translateZ(-2211px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-141deg) rotateY(192deg) rotateZ(157deg) translateZ(-2211px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly12 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(376deg) rotateY(286deg) rotateZ(365deg) translateZ(-863px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(376deg) rotateY(286deg) rotateZ(365deg) translateZ(-863px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly12 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(376deg) rotateY(286deg) rotateZ(365deg) translateZ(-863px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(376deg) rotateY(286deg) rotateZ(365deg) translateZ(-863px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly13 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-208deg) rotateY(-319deg) rotateZ(-3deg) translateZ(-1584px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-208deg) rotateY(-319deg) rotateZ(-3deg) translateZ(-1584px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly13 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-208deg) rotateY(-319deg) rotateZ(-3deg) translateZ(-1584px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-208deg) rotateY(-319deg) rotateZ(-3deg) translateZ(-1584px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly14 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-379deg) rotateY(50deg) rotateZ(-114deg) translateZ(-2202px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-379deg) rotateY(50deg) rotateZ(-114deg) translateZ(-2202px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly14 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-379deg) rotateY(50deg) rotateZ(-114deg) translateZ(-2202px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-379deg) rotateY(50deg) rotateZ(-114deg) translateZ(-2202px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly15 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-113deg) rotateY(-331deg) rotateZ(297deg) translateZ(-2210px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-113deg) rotateY(-331deg) rotateZ(297deg) translateZ(-2210px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly15 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-113deg) rotateY(-331deg) rotateZ(297deg) translateZ(-2210px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-113deg) rotateY(-331deg) rotateZ(297deg) translateZ(-2210px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly16 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-170deg) rotateY(25deg) rotateZ(-433deg) translateZ(-1294px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-170deg) rotateY(25deg) rotateZ(-433deg) translateZ(-1294px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly16 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-170deg) rotateY(25deg) rotateZ(-433deg) translateZ(-1294px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-170deg) rotateY(25deg) rotateZ(-433deg) translateZ(-1294px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly17 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-128deg) rotateY(317deg) rotateZ(317deg) translateZ(-616px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-128deg) rotateY(317deg) rotateZ(317deg) translateZ(-616px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly17 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-128deg) rotateY(317deg) rotateZ(317deg) translateZ(-616px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-128deg) rotateY(317deg) rotateZ(317deg) translateZ(-616px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly18 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-354deg) rotateY(127deg) rotateZ(63deg) translateZ(-569px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-354deg) rotateY(127deg) rotateZ(63deg) translateZ(-569px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly18 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-354deg) rotateY(127deg) rotateZ(63deg) translateZ(-569px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-354deg) rotateY(127deg) rotateZ(63deg) translateZ(-569px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly19 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(304deg) rotateY(-229deg) rotateZ(346deg) translateZ(-2129px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(304deg) rotateY(-229deg) rotateZ(346deg) translateZ(-2129px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly19 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(304deg) rotateY(-229deg) rotateZ(346deg) translateZ(-2129px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(304deg) rotateY(-229deg) rotateZ(346deg) translateZ(-2129px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly20 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(445deg) rotateY(-66deg) rotateZ(-461deg) translateZ(-779px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(445deg) rotateY(-66deg) rotateZ(-461deg) translateZ(-779px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly20 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(445deg) rotateY(-66deg) rotateZ(-461deg) translateZ(-779px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(445deg) rotateY(-66deg) rotateZ(-461deg) translateZ(-779px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly21 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-486deg) rotateY(-194deg) rotateZ(-489deg) translateZ(134px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-486deg) rotateY(-194deg) rotateZ(-489deg) translateZ(134px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly21 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-486deg) rotateY(-194deg) rotateZ(-489deg) translateZ(134px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-486deg) rotateY(-194deg) rotateZ(-489deg) translateZ(134px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly22 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-417deg) rotateY(140deg) rotateZ(-158deg) translateZ(-1496px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-417deg) rotateY(140deg) rotateZ(-158deg) translateZ(-1496px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly22 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-417deg) rotateY(140deg) rotateZ(-158deg) translateZ(-1496px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-417deg) rotateY(140deg) rotateZ(-158deg) translateZ(-1496px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly23 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-406deg) rotateZ(500deg) translateZ(-1395px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-406deg) rotateZ(500deg) translateZ(-1395px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly23 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-406deg) rotateZ(500deg) translateZ(-1395px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-406deg) rotateZ(500deg) translateZ(-1395px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly24 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(293deg) rotateY(472deg) rotateZ(149deg) translateZ(-2298px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(293deg) rotateY(472deg) rotateZ(149deg) translateZ(-2298px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly24 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(293deg) rotateY(472deg) rotateZ(149deg) translateZ(-2298px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(293deg) rotateY(472deg) rotateZ(149deg) translateZ(-2298px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly25 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(129deg) rotateY(-315deg) rotateZ(-494deg) translateZ(-286px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(129deg) rotateY(-315deg) rotateZ(-494deg) translateZ(-286px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly25 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(129deg) rotateY(-315deg) rotateZ(-494deg) translateZ(-286px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(129deg) rotateY(-315deg) rotateZ(-494deg) translateZ(-286px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly26 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-487deg) rotateZ(-53deg) translateZ(-1370px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-487deg) rotateZ(-53deg) translateZ(-1370px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly26 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-487deg) rotateZ(-53deg) translateZ(-1370px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-487deg) rotateZ(-53deg) translateZ(-1370px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly27 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(344deg) rotateY(396deg) rotateZ(-397deg) translateZ(-92px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(344deg) rotateY(396deg) rotateZ(-397deg) translateZ(-92px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly27 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(344deg) rotateY(396deg) rotateZ(-397deg) translateZ(-92px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(344deg) rotateY(396deg) rotateZ(-397deg) translateZ(-92px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly28 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(243deg) rotateY(357deg) rotateZ(22deg) translateZ(-2237px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(243deg) rotateY(357deg) rotateZ(22deg) translateZ(-2237px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly28 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(243deg) rotateY(357deg) rotateZ(22deg) translateZ(-2237px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(243deg) rotateY(357deg) rotateZ(22deg) translateZ(-2237px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly29 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-40deg) rotateY(397deg) rotateZ(-171deg) translateZ(-779px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-40deg) rotateY(397deg) rotateZ(-171deg) translateZ(-779px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly29 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-40deg) rotateY(397deg) rotateZ(-171deg) translateZ(-779px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-40deg) rotateY(397deg) rotateZ(-171deg) translateZ(-779px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly30 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-120deg) rotateY(5deg) rotateZ(-351deg) translateZ(73px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-120deg) rotateY(5deg) rotateZ(-351deg) translateZ(73px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly30 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-120deg) rotateY(5deg) rotateZ(-351deg) translateZ(73px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-120deg) rotateY(5deg) rotateZ(-351deg) translateZ(73px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly31 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(54deg) rotateY(-340deg) rotateZ(-183deg) translateZ(-2235px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(54deg) rotateY(-340deg) rotateZ(-183deg) translateZ(-2235px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly31 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(54deg) rotateY(-340deg) rotateZ(-183deg) translateZ(-2235px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(54deg) rotateY(-340deg) rotateZ(-183deg) translateZ(-2235px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly32 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-235deg) rotateY(-437deg) rotateZ(-180deg) translateZ(-2437px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-235deg) rotateY(-437deg) rotateZ(-180deg) translateZ(-2437px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly32 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-235deg) rotateY(-437deg) rotateZ(-180deg) translateZ(-2437px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-235deg) rotateY(-437deg) rotateZ(-180deg) translateZ(-2437px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly33 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(193deg) rotateY(-209deg) rotateZ(-113deg) translateZ(358px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(193deg) rotateY(-209deg) rotateZ(-113deg) translateZ(358px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly33 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(193deg) rotateY(-209deg) rotateZ(-113deg) translateZ(358px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(193deg) rotateY(-209deg) rotateZ(-113deg) translateZ(358px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly34 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-95deg) rotateY(246deg) rotateZ(418deg) translateZ(157px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-95deg) rotateY(246deg) rotateZ(418deg) translateZ(157px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly34 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-95deg) rotateY(246deg) rotateZ(418deg) translateZ(157px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-95deg) rotateY(246deg) rotateZ(418deg) translateZ(157px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly35 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(181deg) rotateY(-198deg) rotateZ(-492deg) translateZ(466px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(181deg) rotateY(-198deg) rotateZ(-492deg) translateZ(466px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly35 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(181deg) rotateY(-198deg) rotateZ(-492deg) translateZ(466px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(181deg) rotateY(-198deg) rotateZ(-492deg) translateZ(466px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly36 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(10deg) rotateY(-288deg) rotateZ(-206deg) translateZ(-2034px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(10deg) rotateY(-288deg) rotateZ(-206deg) translateZ(-2034px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly36 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(10deg) rotateY(-288deg) rotateZ(-206deg) translateZ(-2034px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(10deg) rotateY(-288deg) rotateZ(-206deg) translateZ(-2034px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly37 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-365deg) rotateY(-104deg) rotateZ(-97deg) translateZ(-1926px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-365deg) rotateY(-104deg) rotateZ(-97deg) translateZ(-1926px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly37 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-365deg) rotateY(-104deg) rotateZ(-97deg) translateZ(-1926px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-365deg) rotateY(-104deg) rotateZ(-97deg) translateZ(-1926px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly38 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(106deg) rotateZ(224deg) translateZ(-2325px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(106deg) rotateZ(224deg) translateZ(-2325px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly38 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(106deg) rotateZ(224deg) translateZ(-2325px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(106deg) rotateZ(224deg) translateZ(-2325px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly39 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(500deg) rotateY(273deg) rotateZ(-57deg) translateZ(-1804px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(500deg) rotateY(273deg) rotateZ(-57deg) translateZ(-1804px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly39 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(500deg) rotateY(273deg) rotateZ(-57deg) translateZ(-1804px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(500deg) rotateY(273deg) rotateZ(-57deg) translateZ(-1804px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly40 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-105deg) rotateY(-116deg) rotateZ(-168deg) translateZ(-843px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-105deg) rotateY(-116deg) rotateZ(-168deg) translateZ(-843px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly40 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-105deg) rotateY(-116deg) rotateZ(-168deg) translateZ(-843px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-105deg) rotateY(-116deg) rotateZ(-168deg) translateZ(-843px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly41 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-274deg) rotateY(-21deg) rotateZ(498deg) translateZ(-1432px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-274deg) rotateY(-21deg) rotateZ(498deg) translateZ(-1432px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly41 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-274deg) rotateY(-21deg) rotateZ(498deg) translateZ(-1432px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-274deg) rotateY(-21deg) rotateZ(498deg) translateZ(-1432px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly42 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-1deg) rotateY(40deg) rotateZ(-318deg) translateZ(-492px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-1deg) rotateY(40deg) rotateZ(-318deg) translateZ(-492px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly42 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-1deg) rotateY(40deg) rotateZ(-318deg) translateZ(-492px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-1deg) rotateY(40deg) rotateZ(-318deg) translateZ(-492px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly43 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-37deg) rotateY(405deg) rotateZ(-447deg) translateZ(-2413px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-37deg) rotateY(405deg) rotateZ(-447deg) translateZ(-2413px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly43 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-37deg) rotateY(405deg) rotateZ(-447deg) translateZ(-2413px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-37deg) rotateY(405deg) rotateZ(-447deg) translateZ(-2413px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly44 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(369deg) rotateY(159deg) rotateZ(-358deg) translateZ(-1174px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(369deg) rotateY(159deg) rotateZ(-358deg) translateZ(-1174px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly44 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(369deg) rotateY(159deg) rotateZ(-358deg) translateZ(-1174px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(369deg) rotateY(159deg) rotateZ(-358deg) translateZ(-1174px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly45 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-7deg) rotateY(423deg) rotateZ(-452deg) translateZ(-477px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-7deg) rotateY(423deg) rotateZ(-452deg) translateZ(-477px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly45 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-7deg) rotateY(423deg) rotateZ(-452deg) translateZ(-477px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-7deg) rotateY(423deg) rotateZ(-452deg) translateZ(-477px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly46 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(191deg) rotateZ(40deg) translateZ(-2054px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(191deg) rotateZ(40deg) translateZ(-2054px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly46 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(191deg) rotateZ(40deg) translateZ(-2054px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(191deg) rotateZ(40deg) translateZ(-2054px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly47 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(400deg) rotateY(415deg) rotateZ(-167deg) translateZ(-1489px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(400deg) rotateY(415deg) rotateZ(-167deg) translateZ(-1489px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly47 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(400deg) rotateY(415deg) rotateZ(-167deg) translateZ(-1489px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(400deg) rotateY(415deg) rotateZ(-167deg) translateZ(-1489px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly48 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-425deg) rotateY(-109deg) rotateZ(-76deg) translateZ(-2056px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-425deg) rotateY(-109deg) rotateZ(-76deg) translateZ(-2056px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly48 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-425deg) rotateY(-109deg) rotateZ(-76deg) translateZ(-2056px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-425deg) rotateY(-109deg) rotateZ(-76deg) translateZ(-2056px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly49 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-348deg) rotateY(267deg) rotateZ(136deg) translateZ(197px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-348deg) rotateY(267deg) rotateZ(136deg) translateZ(197px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly49 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-348deg) rotateY(267deg) rotateZ(136deg) translateZ(197px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-348deg) rotateY(267deg) rotateZ(136deg) translateZ(197px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly50 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(296deg) rotateY(319deg) rotateZ(374deg) translateZ(-862px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(296deg) rotateY(319deg) rotateZ(374deg) translateZ(-862px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly50 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(296deg) rotateY(319deg) rotateZ(374deg) translateZ(-862px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(296deg) rotateY(319deg) rotateZ(374deg) translateZ(-862px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly51 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(9deg) rotateZ(-309deg) translateZ(434px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(9deg) rotateZ(-309deg) translateZ(434px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly51 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(9deg) rotateZ(-309deg) translateZ(434px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(9deg) rotateZ(-309deg) translateZ(434px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly52 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(-434deg) rotateZ(162deg) translateZ(-1928px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(-434deg) rotateZ(162deg) translateZ(-1928px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly52 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(-434deg) rotateZ(162deg) translateZ(-1928px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-392deg) rotateY(-434deg) rotateZ(162deg) translateZ(-1928px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly53 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-248deg) rotateY(406deg) rotateZ(-341deg) translateZ(-1312px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-248deg) rotateY(406deg) rotateZ(-341deg) translateZ(-1312px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly53 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-248deg) rotateY(406deg) rotateZ(-341deg) translateZ(-1312px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-248deg) rotateY(406deg) rotateZ(-341deg) translateZ(-1312px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly54 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-448deg) rotateY(-102deg) rotateZ(350deg) translateZ(-1077px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-448deg) rotateY(-102deg) rotateZ(350deg) translateZ(-1077px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly54 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-448deg) rotateY(-102deg) rotateZ(350deg) translateZ(-1077px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-448deg) rotateY(-102deg) rotateZ(350deg) translateZ(-1077px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly55 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-106deg) rotateY(95deg) rotateZ(-332deg) translateZ(-1713px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-106deg) rotateY(95deg) rotateZ(-332deg) translateZ(-1713px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly55 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-106deg) rotateY(95deg) rotateZ(-332deg) translateZ(-1713px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-106deg) rotateY(95deg) rotateZ(-332deg) translateZ(-1713px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly56 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(27deg) rotateY(-379deg) rotateZ(200deg) translateZ(-535px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(27deg) rotateY(-379deg) rotateZ(200deg) translateZ(-535px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly56 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(27deg) rotateY(-379deg) rotateZ(200deg) translateZ(-535px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(27deg) rotateY(-379deg) rotateZ(200deg) translateZ(-535px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly57 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(498deg) rotateY(14deg) rotateZ(66deg) translateZ(342px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(498deg) rotateY(14deg) rotateZ(66deg) translateZ(342px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly57 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(498deg) rotateY(14deg) rotateZ(66deg) translateZ(342px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(498deg) rotateY(14deg) rotateZ(66deg) translateZ(342px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly58 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-218deg) rotateY(237deg) rotateZ(-243deg) translateZ(-2129px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-218deg) rotateY(237deg) rotateZ(-243deg) translateZ(-2129px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly58 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-218deg) rotateY(237deg) rotateZ(-243deg) translateZ(-2129px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-218deg) rotateY(237deg) rotateZ(-243deg) translateZ(-2129px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly59 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(313deg) rotateY(-101deg) rotateZ(416deg) translateZ(-136px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(313deg) rotateY(-101deg) rotateZ(416deg) translateZ(-136px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly59 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(313deg) rotateY(-101deg) rotateZ(416deg) translateZ(-136px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(313deg) rotateY(-101deg) rotateZ(416deg) translateZ(-136px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly60 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(351deg) rotateY(-118deg) rotateZ(116deg) translateZ(-141px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(351deg) rotateY(-118deg) rotateZ(116deg) translateZ(-141px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly60 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(351deg) rotateY(-118deg) rotateZ(116deg) translateZ(-141px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(351deg) rotateY(-118deg) rotateZ(116deg) translateZ(-141px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly61 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-376deg) rotateY(-479deg) rotateZ(-112deg) translateZ(257px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-376deg) rotateY(-479deg) rotateZ(-112deg) translateZ(257px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly61 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-376deg) rotateY(-479deg) rotateZ(-112deg) translateZ(257px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-376deg) rotateY(-479deg) rotateZ(-112deg) translateZ(257px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly62 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-26deg) rotateY(-146deg) rotateZ(449deg) translateZ(-620px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-26deg) rotateY(-146deg) rotateZ(449deg) translateZ(-620px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly62 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-26deg) rotateY(-146deg) rotateZ(449deg) translateZ(-620px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-26deg) rotateY(-146deg) rotateZ(449deg) translateZ(-620px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly63 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(458deg) rotateY(-83deg) rotateZ(318deg) translateZ(289px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(458deg) rotateY(-83deg) rotateZ(318deg) translateZ(289px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly63 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(458deg) rotateY(-83deg) rotateZ(318deg) translateZ(289px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(458deg) rotateY(-83deg) rotateZ(318deg) translateZ(289px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly64 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(212deg) rotateY(-53deg) rotateZ(-347deg) translateZ(-474px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(212deg) rotateY(-53deg) rotateZ(-347deg) translateZ(-474px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly64 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(212deg) rotateY(-53deg) rotateZ(-347deg) translateZ(-474px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(212deg) rotateY(-53deg) rotateZ(-347deg) translateZ(-474px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly65 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(142deg) rotateY(322deg) rotateZ(-352deg) translateZ(-2055px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(142deg) rotateY(322deg) rotateZ(-352deg) translateZ(-2055px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly65 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(142deg) rotateY(322deg) rotateZ(-352deg) translateZ(-2055px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(142deg) rotateY(322deg) rotateZ(-352deg) translateZ(-2055px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly66 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(461deg) rotateY(81deg) rotateZ(492deg) translateZ(-272px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(461deg) rotateY(81deg) rotateZ(492deg) translateZ(-272px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly66 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(461deg) rotateY(81deg) rotateZ(492deg) translateZ(-272px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(461deg) rotateY(81deg) rotateZ(492deg) translateZ(-272px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly67 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-450deg) rotateY(-338deg) rotateZ(-11deg) translateZ(-2164px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-450deg) rotateY(-338deg) rotateZ(-11deg) translateZ(-2164px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly67 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-450deg) rotateY(-338deg) rotateZ(-11deg) translateZ(-2164px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-450deg) rotateY(-338deg) rotateZ(-11deg) translateZ(-2164px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly68 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-123deg) rotateZ(50deg) translateZ(-1245px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-123deg) rotateZ(50deg) translateZ(-1245px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly68 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-123deg) rotateZ(50deg) translateZ(-1245px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(446deg) rotateY(-123deg) rotateZ(50deg) translateZ(-1245px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly69 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(434deg) rotateY(277deg) rotateZ(338deg) translateZ(-717px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(434deg) rotateY(277deg) rotateZ(338deg) translateZ(-717px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly69 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(434deg) rotateY(277deg) rotateZ(338deg) translateZ(-717px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(434deg) rotateY(277deg) rotateZ(338deg) translateZ(-717px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly70 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(470deg) rotateY(-436deg) rotateZ(499deg) translateZ(-669px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(470deg) rotateY(-436deg) rotateZ(499deg) translateZ(-669px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly70 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(470deg) rotateY(-436deg) rotateZ(499deg) translateZ(-669px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(470deg) rotateY(-436deg) rotateZ(499deg) translateZ(-669px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly71 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(395deg) rotateY(-498deg) rotateZ(314deg) translateZ(-2367px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(395deg) rotateY(-498deg) rotateZ(314deg) translateZ(-2367px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly71 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(395deg) rotateY(-498deg) rotateZ(314deg) translateZ(-2367px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(395deg) rotateY(-498deg) rotateZ(314deg) translateZ(-2367px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly72 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(16deg) rotateY(93deg) rotateZ(-115deg) translateZ(-1531px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(16deg) rotateY(93deg) rotateZ(-115deg) translateZ(-1531px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly72 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(16deg) rotateY(93deg) rotateZ(-115deg) translateZ(-1531px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(16deg) rotateY(93deg) rotateZ(-115deg) translateZ(-1531px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly73 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-228deg) rotateY(175deg) rotateZ(158deg) translateZ(-1597px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-228deg) rotateY(175deg) rotateZ(158deg) translateZ(-1597px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly73 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-228deg) rotateY(175deg) rotateZ(158deg) translateZ(-1597px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-228deg) rotateY(175deg) rotateZ(158deg) translateZ(-1597px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly74 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-303deg) rotateY(262deg) rotateZ(445deg) translateZ(-1053px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-303deg) rotateY(262deg) rotateZ(445deg) translateZ(-1053px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly74 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-303deg) rotateY(262deg) rotateZ(445deg) translateZ(-1053px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-303deg) rotateY(262deg) rotateZ(445deg) translateZ(-1053px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly75 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(462deg) rotateY(-258deg) rotateZ(-85deg) translateZ(-898px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(462deg) rotateY(-258deg) rotateZ(-85deg) translateZ(-898px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly75 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(462deg) rotateY(-258deg) rotateZ(-85deg) translateZ(-898px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(462deg) rotateY(-258deg) rotateZ(-85deg) translateZ(-898px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly76 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(237deg) rotateY(327deg) rotateZ(-6deg) translateZ(215px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(237deg) rotateY(327deg) rotateZ(-6deg) translateZ(215px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly76 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(237deg) rotateY(327deg) rotateZ(-6deg) translateZ(215px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(237deg) rotateY(327deg) rotateZ(-6deg) translateZ(215px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly77 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(67deg) rotateY(166deg) rotateZ(-263deg) translateZ(-718px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(67deg) rotateY(166deg) rotateZ(-263deg) translateZ(-718px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly77 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(67deg) rotateY(166deg) rotateZ(-263deg) translateZ(-718px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(67deg) rotateY(166deg) rotateZ(-263deg) translateZ(-718px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly78 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-20deg) rotateY(-51deg) rotateZ(-456deg) translateZ(-1790px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-20deg) rotateY(-51deg) rotateZ(-456deg) translateZ(-1790px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly78 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-20deg) rotateY(-51deg) rotateZ(-456deg) translateZ(-1790px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-20deg) rotateY(-51deg) rotateZ(-456deg) translateZ(-1790px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly79 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(-132deg) rotateZ(324deg) translateZ(8px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(-132deg) rotateZ(324deg) translateZ(8px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly79 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(-132deg) rotateZ(324deg) translateZ(8px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(253deg) rotateY(-132deg) rotateZ(324deg) translateZ(8px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly80 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-137deg) rotateY(-156deg) rotateZ(414deg) translateZ(-184px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-137deg) rotateY(-156deg) rotateZ(414deg) translateZ(-184px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly80 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-137deg) rotateY(-156deg) rotateZ(414deg) translateZ(-184px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-137deg) rotateY(-156deg) rotateZ(414deg) translateZ(-184px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly81 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(295deg) rotateY(55deg) rotateZ(-211deg) translateZ(-470px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(295deg) rotateY(55deg) rotateZ(-211deg) translateZ(-470px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly81 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(295deg) rotateY(55deg) rotateZ(-211deg) translateZ(-470px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(295deg) rotateY(55deg) rotateZ(-211deg) translateZ(-470px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly82 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(160deg) rotateY(-91deg) rotateZ(-237deg) translateZ(23px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(160deg) rotateY(-91deg) rotateZ(-237deg) translateZ(23px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly82 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(160deg) rotateY(-91deg) rotateZ(-237deg) translateZ(23px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(160deg) rotateY(-91deg) rotateZ(-237deg) translateZ(23px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly83 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-488deg) rotateY(-44deg) rotateZ(-392deg) translateZ(-1227px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-488deg) rotateY(-44deg) rotateZ(-392deg) translateZ(-1227px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly83 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-488deg) rotateY(-44deg) rotateZ(-392deg) translateZ(-1227px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-488deg) rotateY(-44deg) rotateZ(-392deg) translateZ(-1227px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly84 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(172deg) rotateY(265deg) rotateZ(-343deg) translateZ(-411px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(172deg) rotateY(265deg) rotateZ(-343deg) translateZ(-411px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly84 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(172deg) rotateY(265deg) rotateZ(-343deg) translateZ(-411px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(172deg) rotateY(265deg) rotateZ(-343deg) translateZ(-411px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly85 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-221deg) rotateY(-429deg) rotateZ(247deg) translateZ(-2237px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-221deg) rotateY(-429deg) rotateZ(247deg) translateZ(-2237px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly85 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-221deg) rotateY(-429deg) rotateZ(247deg) translateZ(-2237px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-221deg) rotateY(-429deg) rotateZ(247deg) translateZ(-2237px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly86 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(45deg) rotateY(-88deg) rotateZ(391deg) translateZ(-2041px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(45deg) rotateY(-88deg) rotateZ(391deg) translateZ(-2041px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly86 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(45deg) rotateY(-88deg) rotateZ(391deg) translateZ(-2041px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(45deg) rotateY(-88deg) rotateZ(391deg) translateZ(-2041px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly87 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-96deg) rotateY(-494deg) rotateZ(-446deg) translateZ(437px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-96deg) rotateY(-494deg) rotateZ(-446deg) translateZ(437px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly87 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-96deg) rotateY(-494deg) rotateZ(-446deg) translateZ(437px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-96deg) rotateY(-494deg) rotateZ(-446deg) translateZ(437px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly88 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-364deg) rotateY(-34deg) rotateZ(376deg) translateZ(297px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-364deg) rotateY(-34deg) rotateZ(376deg) translateZ(297px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly88 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-364deg) rotateY(-34deg) rotateZ(376deg) translateZ(297px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-364deg) rotateY(-34deg) rotateZ(376deg) translateZ(297px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly89 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-434deg) rotateY(105deg) rotateZ(117deg) translateZ(-194px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-434deg) rotateY(105deg) rotateZ(117deg) translateZ(-194px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly89 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-434deg) rotateY(105deg) rotateZ(117deg) translateZ(-194px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-434deg) rotateY(105deg) rotateZ(117deg) translateZ(-194px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly90 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(484deg) rotateY(-215deg) rotateZ(-496deg) translateZ(-1668px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(484deg) rotateY(-215deg) rotateZ(-496deg) translateZ(-1668px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly90 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(484deg) rotateY(-215deg) rotateZ(-496deg) translateZ(-1668px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(484deg) rotateY(-215deg) rotateZ(-496deg) translateZ(-1668px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly91 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-166deg) rotateZ(-283deg) translateZ(-956px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-166deg) rotateZ(-283deg) translateZ(-956px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly91 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-166deg) rotateZ(-283deg) translateZ(-956px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(120deg) rotateY(-166deg) rotateZ(-283deg) translateZ(-956px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly92 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-390deg) rotateZ(79deg) translateZ(314px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-390deg) rotateZ(79deg) translateZ(314px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly92 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-390deg) rotateZ(79deg) translateZ(314px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(89deg) rotateY(-390deg) rotateZ(79deg) translateZ(314px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly93 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(356deg) rotateZ(-137deg) translateZ(-843px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(356deg) rotateZ(-137deg) translateZ(-843px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly93 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(356deg) rotateZ(-137deg) translateZ(-843px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(44deg) rotateY(356deg) rotateZ(-137deg) translateZ(-843px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly94 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(419deg) rotateY(36deg) rotateZ(-360deg) translateZ(-1650px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(419deg) rotateY(36deg) rotateZ(-360deg) translateZ(-1650px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly94 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(419deg) rotateY(36deg) rotateZ(-360deg) translateZ(-1650px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(419deg) rotateY(36deg) rotateZ(-360deg) translateZ(-1650px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly95 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-284deg) rotateY(-274deg) rotateZ(317deg) translateZ(-2496px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-284deg) rotateY(-274deg) rotateZ(317deg) translateZ(-2496px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly95 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-284deg) rotateY(-274deg) rotateZ(317deg) translateZ(-2496px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-284deg) rotateY(-274deg) rotateZ(317deg) translateZ(-2496px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly96 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(468deg) rotateY(21deg) rotateZ(145deg) translateZ(246px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(468deg) rotateY(21deg) rotateZ(145deg) translateZ(246px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly96 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(468deg) rotateY(21deg) rotateZ(145deg) translateZ(246px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(468deg) rotateY(21deg) rotateZ(145deg) translateZ(246px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly97 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-108deg) rotateY(466deg) rotateZ(-397deg) translateZ(-856px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-108deg) rotateY(466deg) rotateZ(-397deg) translateZ(-856px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly97 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-108deg) rotateY(466deg) rotateZ(-397deg) translateZ(-856px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-108deg) rotateY(466deg) rotateZ(-397deg) translateZ(-856px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly98 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(77deg) rotateY(-328deg) rotateZ(39deg) translateZ(-2090px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(77deg) rotateY(-328deg) rotateZ(39deg) translateZ(-2090px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly98 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(77deg) rotateY(-328deg) rotateZ(39deg) translateZ(-2090px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(77deg) rotateY(-328deg) rotateZ(39deg) translateZ(-2090px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly99 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-435deg) rotateY(-455deg) rotateZ(395deg) translateZ(-1165px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-435deg) rotateY(-455deg) rotateZ(395deg) translateZ(-1165px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly99 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-435deg) rotateY(-455deg) rotateZ(395deg) translateZ(-1165px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-435deg) rotateY(-455deg) rotateZ(395deg) translateZ(-1165px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly100 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-397deg) rotateY(340deg) rotateZ(-401deg) translateZ(-229px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-397deg) rotateY(340deg) rotateZ(-401deg) translateZ(-229px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly100 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-397deg) rotateY(340deg) rotateZ(-401deg) translateZ(-229px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(-397deg) rotateY(340deg) rotateZ(-401deg) translateZ(-229px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@-webkit-keyframes fly101 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(85deg) rotateY(-53deg) rotateZ(426deg) translateZ(-1268px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(85deg) rotateY(-53deg) rotateZ(426deg) translateZ(-1268px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

@keyframes fly101 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(85deg) rotateY(-53deg) rotateZ(426deg) translateZ(-1268px);
  }
  10% {
    opacity: 0;
    transform: translate(-50%, -50%) rotateX(85deg) rotateY(-53deg) rotateZ(426deg) translateZ(-1268px);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
  }
}

html body { 
  padding: 0px;
  margin: 0px;
}

body {
  font-family: Poppins, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  width: 100%;
  height: 100%;
}

.text-justify {
  text-align: justify;
}

.dropdown-toggle::after {
  content: none;
}

.rti--container {
  padding: 0;
  margin-bottom: 0.5rem;
  border: none !important;
  background-color: #F5F8FA;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.rti--container:active,
.rti--container.active,
.rti--container:focus,
.rti--container:focus-within {
  border-color: inherit !important;
  box-shadow: none !important;
  background-color: #eef3f7;
  border-color: #eef3f7;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.tag-cls {
  white-space: pre-wrap;
  padding: 0.5rem 0 0.5rem 0.75rem;
  margin: 0.5rem 0.5rem 0;
}

.input-cls {
  width: 100%;
}

.template-model {
  overflow: hidden;
  height: 100px;
  width: 100px;
  position: relative;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, .3);
  transition: .5s;
  background-color: #192440
}

.template-model:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: #8950FC;
  opacity: 0.8;
  border-radius: 50%;
  transition: .8s
}

.template-model:hover:after {
  transform: translate(-50%, -50%)
}

.template-model:hover img {
  transform: translate(-50%, -50%) scale(1.3) rotate(20deg)
}

.template-model-image {
  position: absolute;
  height: 110%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .8s
}

.template-model-label {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #FFFFFF;
  transition: .8s;
  transition-timing-function: ease-in
}

.template-model:hover label {
  transform: translate(-50%, -50%);
  transition-timing-function: ease
}

.mask {
  position: absolute;
  border-radius: 2px;
  overflow: hidden;
  perspective: 1000px;
  backface-visibility: hidden;
}

.plane {
  background: #8950FC;
  width: 400%;
  height: 100%;
  position: absolute;
  transform: translate3d(0px, 0, 0);
  z-index: 100;
  perspective: 1000px;
  backface-visibility: hidden;
}

.animation {
  transition: all 0.3s ease;
}

.global {
  width: 70px;
  height: 60px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
}

.global-top .plane {
  z-index: 2000;
  animation: trans1 1.3s ease-in infinite 0s backwards;
}

.global-middle .plane {
  transform: translate3d(0px, 0, 0);
  background: #323248;
  animation: trans2 1.3s linear infinite 0.3s backwards;
}

.global-bottom .plane {
  z-index: 2000;
  animation: trans3 1.3s ease-out infinite 0.7s backwards;
}

.global-top {
  width: 53px;
  height: 20px;
  left: 20px;
  transform: skew(-15deg, 0);
  z-index: 100;
}

.global-middle {
  width: 33px;
  height: 20px;
  left: 20px;
  top: 15px;
  transform: skew(-15deg, 40deg);
}

.global-bottom {
  width: 53px;
  height: 20px;
  top: 30px;  
  transform: skew(-15deg, 0);
}

.global-text {
  color: #000000;
  position: absolute;
  text-transform: uppercase;
  left: -3px;
  top: 55px;
  text-align: center;
  font-size: 10px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #192440;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.div-3d {
  transform-style: preserve-3d;
}

.load-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui .load-text {
  position: absolute;
  font-size: 7rem;
  font-weight: 900;
  line-height: 8rem;
  opacity: 0;
  font-family: "Anton", sans-serif;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
}

.ui .load-text:nth-child(1) {
  color: #fd3203;
  -webkit-clip-path: polygon(0% 0%, 0% 50%, 10% 50%);
  clip-path: polygon(0% 0%, 0% 50%, 10% 50%);
  transform-origin: -15% 18%;
  -webkit-animation: fly1 5000ms 0ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly1 5000ms 0ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(2) {
  color: #fb3106;
  -webkit-clip-path: polygon(0% 0%, 10% 0%, 10% 50%);
  clip-path: polygon(0% 0%, 10% 0%, 10% 50%);
  transform-origin: 41% 6%;
  -webkit-animation: fly2 5000ms 40ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly2 5000ms 40ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(3) {
  color: #f93109;
  -webkit-clip-path: polygon(10% 0%, 10% 50%, 20% 50%);
  clip-path: polygon(10% 0%, 10% 50%, 20% 50%);
  transform-origin: 17% 0%;
  -webkit-animation: fly3 5000ms 80ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly3 5000ms 80ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(4) {
  color: #f7300c;
  -webkit-clip-path: polygon(10% 0%, 20% 0%, 20% 50%);
  clip-path: polygon(10% 0%, 20% 0%, 20% 50%);
  transform-origin: 26% -39%;
  -webkit-animation: fly4 5000ms 120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly4 5000ms 120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(5) {
  color: #f5300f;
  -webkit-clip-path: polygon(20% 0%, 20% 50%, 30% 50%);
  clip-path: polygon(20% 0%, 20% 50%, 30% 50%);
  transform-origin: 22% 17%;
  -webkit-animation: fly5 5000ms 160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly5 5000ms 160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(6) {
  color: #f32f12;
  -webkit-clip-path: polygon(20% 0%, 30% 0%, 30% 50%);
  clip-path: polygon(20% 0%, 30% 0%, 30% 50%);
  transform-origin: -5% 14%;
  -webkit-animation: fly6 5000ms 200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly6 5000ms 200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(7) {
  color: #f12f15;
  -webkit-clip-path: polygon(30% 0%, 30% 50%, 40% 50%);
  clip-path: polygon(30% 0%, 30% 50%, 40% 50%);
  transform-origin: 48% 21%;
  -webkit-animation: fly7 5000ms 240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly7 5000ms 240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(8) {
  color: #ef2e18;
  -webkit-clip-path: polygon(30% 0%, 40% 0%, 40% 50%);
  clip-path: polygon(30% 0%, 40% 0%, 40% 50%);
  transform-origin: 36% -47%;
  -webkit-animation: fly8 5000ms 280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly8 5000ms 280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(9) {
  color: #ed2e1b;
  -webkit-clip-path: polygon(40% 0%, 40% 50%, 50% 50%);
  clip-path: polygon(40% 0%, 40% 50%, 50% 50%);
  transform-origin: 20% -47%;
  -webkit-animation: fly9 5000ms 320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly9 5000ms 320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(10) {
  color: #eb2d1e;
  -webkit-clip-path: polygon(40% 0%, 50% 0%, 50% 50%);
  clip-path: polygon(40% 0%, 50% 0%, 50% 50%);
  transform-origin: 21% 40%;
  -webkit-animation: fly10 5000ms 360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly10 5000ms 360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(11) {
  color: #e92d21;
  -webkit-clip-path: polygon(50% 0%, 50% 50%, 60% 50%);
  clip-path: polygon(50% 0%, 50% 50%, 60% 50%);
  transform-origin: 71% 36%;
  -webkit-animation: fly11 5000ms 400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly11 5000ms 400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(12) {
  color: #e72c24;
  -webkit-clip-path: polygon(50% 0%, 60% 0%, 60% 50%);
  clip-path: polygon(50% 0%, 60% 0%, 60% 50%);
  transform-origin: 58% 14%;
  -webkit-animation: fly12 5000ms 440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly12 5000ms 440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(13) {
  color: #e52c27;
  -webkit-clip-path: polygon(60% 0%, 60% 50%, 70% 50%);
  clip-path: polygon(60% 0%, 60% 50%, 70% 50%);
  transform-origin: 104% 38%;
  -webkit-animation: fly13 5000ms 480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly13 5000ms 480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(14) {
  color: #e32b2a;
  -webkit-clip-path: polygon(60% 0%, 70% 0%, 70% 50%);
  clip-path: polygon(60% 0%, 70% 0%, 70% 50%);
  transform-origin: 17% -28%;
  -webkit-animation: fly14 5000ms 520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly14 5000ms 520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(15) {
  color: #e12b2d;
  -webkit-clip-path: polygon(70% 0%, 70% 50%, 80% 50%);
  clip-path: polygon(70% 0%, 70% 50%, 80% 50%);
  transform-origin: 80% -22%;
  -webkit-animation: fly15 5000ms 560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly15 5000ms 560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(16) {
  color: #df2a30;
  -webkit-clip-path: polygon(70% 0%, 80% 0%, 80% 50%);
  clip-path: polygon(70% 0%, 80% 0%, 80% 50%);
  transform-origin: 34% -30%;
  -webkit-animation: fly16 5000ms 600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly16 5000ms 600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(17) {
  color: #dd2a33;
  -webkit-clip-path: polygon(80% 0%, 80% 50%, 90% 50%);
  clip-path: polygon(80% 0%, 80% 50%, 90% 50%);
  transform-origin: 100% 11%;
  -webkit-animation: fly17 5000ms 640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly17 5000ms 640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(18) {
  color: #db2936;
  -webkit-clip-path: polygon(80% 0%, 90% 0%, 90% 50%);
  clip-path: polygon(80% 0%, 90% 0%, 90% 50%);
  transform-origin: 100% -1%;
  -webkit-animation: fly18 5000ms 680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly18 5000ms 680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(19) {
  color: #d92939;
  -webkit-clip-path: polygon(90% 0%, 90% 50%, 100% 50%);
  clip-path: polygon(90% 0%, 90% 50%, 100% 50%);
  transform-origin: 101% -26%;
  -webkit-animation: fly19 5000ms 720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly19 5000ms 720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(20) {
  color: #d7283c;
  -webkit-clip-path: polygon(90% 0%, 100% 0%, 100% 50%);
  clip-path: polygon(90% 0%, 100% 0%, 100% 50%);
  transform-origin: 116% 43%;
  -webkit-animation: fly20 5000ms 760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly20 5000ms 760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(21) {
  color: #d5283f;
  -webkit-clip-path: polygon(0% 50%, 0% 100%, 10% 100%);
  clip-path: polygon(0% 50%, 0% 100%, 10% 100%);
  transform-origin: 37% 14%;
  -webkit-animation: fly21 5000ms 800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly21 5000ms 800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(22) {
  color: #d32742;
  -webkit-clip-path: polygon(0% 50%, 10% 50%, 10% 100%);
  clip-path: polygon(0% 50%, 10% 50%, 10% 100%);
  transform-origin: 1% 88%;
  -webkit-animation: fly22 5000ms 840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly22 5000ms 840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(23) {
  color: #d12745;
  -webkit-clip-path: polygon(10% 50%, 10% 100%, 20% 100%);
  clip-path: polygon(10% 50%, 10% 100%, 20% 100%);
  transform-origin: 59% 21%;
  -webkit-animation: fly23 5000ms 880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly23 5000ms 880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(24) {
  color: #cf2648;
  -webkit-clip-path: polygon(10% 50%, 20% 50%, 20% 100%);
  clip-path: polygon(10% 50%, 20% 50%, 20% 100%);
  transform-origin: -25% 10%;
  -webkit-animation: fly24 5000ms 920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly24 5000ms 920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(25) {
  color: #cd264b;
  -webkit-clip-path: polygon(20% 50%, 20% 100%, 30% 100%);
  clip-path: polygon(20% 50%, 20% 100%, 30% 100%);
  transform-origin: -13% 70%;
  -webkit-animation: fly25 5000ms 960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly25 5000ms 960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(26) {
  color: #cb254e;
  -webkit-clip-path: polygon(20% 50%, 30% 50%, 30% 100%);
  clip-path: polygon(20% 50%, 30% 50%, 30% 100%);
  transform-origin: 10% 68%;
  -webkit-animation: fly26 5000ms 1000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly26 5000ms 1000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(27) {
  color: #c92551;
  -webkit-clip-path: polygon(30% 50%, 30% 100%, 40% 100%);
  clip-path: polygon(30% 50%, 30% 100%, 40% 100%);
  transform-origin: 33% 22%;
  -webkit-animation: fly27 5000ms 1040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly27 5000ms 1040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(28) {
  color: #c72454;
  -webkit-clip-path: polygon(30% 50%, 40% 50%, 40% 100%);
  clip-path: polygon(30% 50%, 40% 50%, 40% 100%);
  transform-origin: 24% 69%;
  -webkit-animation: fly28 5000ms 1080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly28 5000ms 1080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(29) {
  color: #c52457;
  -webkit-clip-path: polygon(40% 50%, 40% 100%, 50% 100%);
  clip-path: polygon(40% 50%, 40% 100%, 50% 100%);
  transform-origin: 13% 38%;
  -webkit-animation: fly29 5000ms 1120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly29 5000ms 1120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(30) {
  color: #c3235a;
  -webkit-clip-path: polygon(40% 50%, 50% 50%, 50% 100%);
  clip-path: polygon(40% 50%, 50% 50%, 50% 100%);
  transform-origin: 28% 71%;
  -webkit-animation: fly30 5000ms 1160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly30 5000ms 1160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(31) {
  color: #c1235d;
  -webkit-clip-path: polygon(50% 50%, 50% 100%, 60% 100%);
  clip-path: polygon(50% 50%, 50% 100%, 60% 100%);
  transform-origin: 25% 91%;
  -webkit-animation: fly31 5000ms 1200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly31 5000ms 1200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(32) {
  color: #bf2260;
  -webkit-clip-path: polygon(50% 50%, 60% 50%, 60% 100%);
  clip-path: polygon(50% 50%, 60% 50%, 60% 100%);
  transform-origin: 14% 4%;
  -webkit-animation: fly32 5000ms 1240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly32 5000ms 1240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(33) {
  color: #bd2263;
  -webkit-clip-path: polygon(60% 50%, 60% 100%, 70% 100%);
  clip-path: polygon(60% 50%, 60% 100%, 70% 100%);
  transform-origin: 56% 23%;
  -webkit-animation: fly33 5000ms 1280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly33 5000ms 1280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(34) {
  color: #bb2166;
  -webkit-clip-path: polygon(60% 50%, 70% 50%, 70% 100%);
  clip-path: polygon(60% 50%, 70% 50%, 70% 100%);
  transform-origin: 109% 89%;
  -webkit-animation: fly34 5000ms 1320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly34 5000ms 1320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(35) {
  color: #b92169;
  -webkit-clip-path: polygon(70% 50%, 70% 100%, 80% 100%);
  clip-path: polygon(70% 50%, 70% 100%, 80% 100%);
  transform-origin: 47% 19%;
  -webkit-animation: fly35 5000ms 1360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly35 5000ms 1360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(36) {
  color: #b7206c;
  -webkit-clip-path: polygon(70% 50%, 80% 50%, 80% 100%);
  clip-path: polygon(70% 50%, 80% 50%, 80% 100%);
  transform-origin: 73% 69%;
  -webkit-animation: fly36 5000ms 1400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly36 5000ms 1400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(37) {
  color: #b5206f;
  -webkit-clip-path: polygon(80% 50%, 80% 100%, 90% 100%);
  clip-path: polygon(80% 50%, 80% 100%, 90% 100%);
  transform-origin: 76% 37%;
  -webkit-animation: fly37 5000ms 1440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly37 5000ms 1440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(38) {
  color: #b31f72;
  -webkit-clip-path: polygon(80% 50%, 90% 50%, 90% 100%);
  clip-path: polygon(80% 50%, 90% 50%, 90% 100%);
  transform-origin: 97% 96%;
  -webkit-animation: fly38 5000ms 1480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly38 5000ms 1480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(39) {
  color: #b11f75;
  -webkit-clip-path: polygon(90% 50%, 90% 100%, 100% 100%);
  clip-path: polygon(90% 50%, 90% 100%, 100% 100%);
  transform-origin: 92% 67%;
  -webkit-animation: fly39 5000ms 1520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly39 5000ms 1520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(40) {
  color: #af1e78;
  -webkit-clip-path: polygon(90% 50%, 100% 50%, 100% 100%);
  clip-path: polygon(90% 50%, 100% 50%, 100% 100%);
  transform-origin: 79% 93%;
  -webkit-animation: fly40 5000ms 1560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly40 5000ms 1560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(41) {
  color: #ad1e7b;
  -webkit-clip-path: polygon(0% 100%, 0% 150%, 10% 150%);
  clip-path: polygon(0% 100%, 0% 150%, 10% 150%);
  transform-origin: -6% 141%;
  -webkit-animation: fly41 5000ms 1600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly41 5000ms 1600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(42) {
  color: #ab1d7e;
  -webkit-clip-path: polygon(0% 100%, 10% 100%, 10% 150%);
  clip-path: polygon(0% 100%, 10% 100%, 10% 150%);
  transform-origin: 42% 115%;
  -webkit-animation: fly42 5000ms 1640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly42 5000ms 1640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(43) {
  color: #a91d81;
  -webkit-clip-path: polygon(10% 100%, 10% 150%, 20% 150%);
  clip-path: polygon(10% 100%, 10% 150%, 20% 150%);
  transform-origin: 31% 111%;
  -webkit-animation: fly43 5000ms 1680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly43 5000ms 1680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(44) {
  color: #a71c84;
  -webkit-clip-path: polygon(10% 100%, 20% 100%, 20% 150%);
  clip-path: polygon(10% 100%, 20% 100%, 20% 150%);
  transform-origin: 39% 70%;
  -webkit-animation: fly44 5000ms 1720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly44 5000ms 1720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(45) {
  color: #a51c87;
  -webkit-clip-path: polygon(20% 100%, 20% 150%, 30% 150%);
  clip-path: polygon(20% 100%, 20% 150%, 30% 150%);
  transform-origin: -24% 102%;
  -webkit-animation: fly45 5000ms 1760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly45 5000ms 1760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(46) {
  color: #a31b8a;
  -webkit-clip-path: polygon(20% 100%, 30% 100%, 30% 150%);
  clip-path: polygon(20% 100%, 30% 100%, 30% 150%);
  transform-origin: 44% 140%;
  -webkit-animation: fly46 5000ms 1800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly46 5000ms 1800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(47) {
  color: #a11b8d;
  -webkit-clip-path: polygon(30% 100%, 30% 150%, 40% 150%);
  clip-path: polygon(30% 100%, 30% 150%, 40% 150%);
  transform-origin: 72% 120%;
  -webkit-animation: fly47 5000ms 1840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly47 5000ms 1840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(48) {
  color: #9f1a90;
  -webkit-clip-path: polygon(30% 100%, 40% 100%, 40% 150%);
  clip-path: polygon(30% 100%, 40% 100%, 40% 150%);
  transform-origin: 80% 76%;
  -webkit-animation: fly48 5000ms 1880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly48 5000ms 1880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(49) {
  color: #9d1a93;
  -webkit-clip-path: polygon(40% 100%, 40% 150%, 50% 150%);
  clip-path: polygon(40% 100%, 40% 150%, 50% 150%);
  transform-origin: 26% 115%;
  -webkit-animation: fly49 5000ms 1920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly49 5000ms 1920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(50) {
  color: #9b1996;
  -webkit-clip-path: polygon(40% 100%, 50% 100%, 50% 150%);
  clip-path: polygon(40% 100%, 50% 100%, 50% 150%);
  transform-origin: 81% 123%;
  -webkit-animation: fly50 5000ms 1960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly50 5000ms 1960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(51) {
  color: #991999;
  -webkit-clip-path: polygon(50% 100%, 50% 150%, 60% 150%);
  clip-path: polygon(50% 100%, 50% 150%, 60% 150%);
  transform-origin: 53% 122%;
  -webkit-animation: fly51 5000ms 2000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly51 5000ms 2000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(52) {
  color: #97189c;
  -webkit-clip-path: polygon(50% 100%, 60% 100%, 60% 150%);
  clip-path: polygon(50% 100%, 60% 100%, 60% 150%);
  transform-origin: 64% 142%;
  -webkit-animation: fly52 5000ms 2040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly52 5000ms 2040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(53) {
  color: #95189f;
  -webkit-clip-path: polygon(60% 100%, 60% 150%, 70% 150%);
  clip-path: polygon(60% 100%, 60% 150%, 70% 150%);
  transform-origin: 96% 97%;
  -webkit-animation: fly53 5000ms 2080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly53 5000ms 2080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(54) {
  color: #9317a2;
  -webkit-clip-path: polygon(60% 100%, 70% 100%, 70% 150%);
  clip-path: polygon(60% 100%, 70% 100%, 70% 150%);
  transform-origin: 84% 130%;
  -webkit-animation: fly54 5000ms 2120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly54 5000ms 2120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(55) {
  color: #9117a5;
  -webkit-clip-path: polygon(70% 100%, 70% 150%, 80% 150%);
  clip-path: polygon(70% 100%, 70% 150%, 80% 150%);
  transform-origin: 87% 57%;
  -webkit-animation: fly55 5000ms 2160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly55 5000ms 2160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(56) {
  color: #8f16a8;
  -webkit-clip-path: polygon(70% 100%, 80% 100%, 80% 150%);
  clip-path: polygon(70% 100%, 80% 100%, 80% 150%);
  transform-origin: 71% 70%;
  -webkit-animation: fly56 5000ms 2200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly56 5000ms 2200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(57) {
  color: #8d16ab;
  -webkit-clip-path: polygon(80% 100%, 80% 150%, 90% 150%);
  clip-path: polygon(80% 100%, 80% 150%, 90% 150%);
  transform-origin: 65% 99%;
  -webkit-animation: fly57 5000ms 2240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly57 5000ms 2240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(58) {
  color: #8b15ae;
  -webkit-clip-path: polygon(80% 100%, 90% 100%, 90% 150%);
  clip-path: polygon(80% 100%, 90% 100%, 90% 150%);
  transform-origin: 124% 81%;
  -webkit-animation: fly58 5000ms 2280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly58 5000ms 2280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(59) {
  color: #8915b1;
  -webkit-clip-path: polygon(90% 100%, 90% 150%, 100% 150%);
  clip-path: polygon(90% 100%, 90% 150%, 100% 150%);
  transform-origin: 117% 104%;
  -webkit-animation: fly59 5000ms 2320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly59 5000ms 2320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(60) {
  color: #8714b4;
  -webkit-clip-path: polygon(90% 100%, 100% 100%, 100% 150%);
  clip-path: polygon(90% 100%, 100% 100%, 100% 150%);
  transform-origin: 97% 90%;
  -webkit-animation: fly60 5000ms 2360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly60 5000ms 2360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(61) {
  color: #8514b7;
  -webkit-clip-path: polygon(0% 150%, 0% 200%, 10% 200%);
  clip-path: polygon(0% 150%, 0% 200%, 10% 200%);
  transform-origin: 38% 114%;
  -webkit-animation: fly61 5000ms 2400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly61 5000ms 2400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(62) {
  color: #8313ba;
  -webkit-clip-path: polygon(0% 150%, 10% 150%, 10% 200%);
  clip-path: polygon(0% 150%, 10% 150%, 10% 200%);
  transform-origin: 34% 166%;
  -webkit-animation: fly62 5000ms 2440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly62 5000ms 2440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(63) {
  color: #8113bd;
  -webkit-clip-path: polygon(10% 150%, 10% 200%, 20% 200%);
  clip-path: polygon(10% 150%, 10% 200%, 20% 200%);
  transform-origin: 34% 160%;
  -webkit-animation: fly63 5000ms 2480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly63 5000ms 2480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(64) {
  color: #7f12c0;
  -webkit-clip-path: polygon(10% 150%, 20% 150%, 20% 200%);
  clip-path: polygon(10% 150%, 20% 150%, 20% 200%);
  transform-origin: 15% 110%;
  -webkit-animation: fly64 5000ms 2520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly64 5000ms 2520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(65) {
  color: #7d12c3;
  -webkit-clip-path: polygon(20% 150%, 20% 200%, 30% 200%);
  clip-path: polygon(20% 150%, 20% 200%, 30% 200%);
  transform-origin: 48% 160%;
  -webkit-animation: fly65 5000ms 2560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly65 5000ms 2560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(66) {
  color: #7b11c6;
  -webkit-clip-path: polygon(20% 150%, 30% 150%, 30% 200%);
  clip-path: polygon(20% 150%, 30% 150%, 30% 200%);
  transform-origin: -15% 163%;
  -webkit-animation: fly66 5000ms 2600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly66 5000ms 2600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(67) {
  color: #7911c9;
  -webkit-clip-path: polygon(30% 150%, 30% 200%, 40% 200%);
  clip-path: polygon(30% 150%, 30% 200%, 40% 200%);
  transform-origin: 60% 159%;
  -webkit-animation: fly67 5000ms 2640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly67 5000ms 2640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(68) {
  color: #7710cc;
  -webkit-clip-path: polygon(30% 150%, 40% 150%, 40% 200%);
  clip-path: polygon(30% 150%, 40% 150%, 40% 200%);
  transform-origin: -3% 104%;
  -webkit-animation: fly68 5000ms 2680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly68 5000ms 2680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(69) {
  color: #7510cf;
  -webkit-clip-path: polygon(40% 150%, 40% 200%, 50% 200%);
  clip-path: polygon(40% 150%, 40% 200%, 50% 200%);
  transform-origin: 41% 131%;
  -webkit-animation: fly69 5000ms 2720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly69 5000ms 2720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(70) {
  color: #730fd2;
  -webkit-clip-path: polygon(40% 150%, 50% 150%, 50% 200%);
  clip-path: polygon(40% 150%, 50% 150%, 50% 200%);
  transform-origin: 45% 150%;
  -webkit-animation: fly70 5000ms 2760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly70 5000ms 2760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(71) {
  color: #710fd5;
  -webkit-clip-path: polygon(50% 150%, 50% 200%, 60% 200%);
  clip-path: polygon(50% 150%, 50% 200%, 60% 200%);
  transform-origin: 46% 145%;
  -webkit-animation: fly71 5000ms 2800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly71 5000ms 2800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(72) {
  color: #6f0ed8;
  -webkit-clip-path: polygon(50% 150%, 60% 150%, 60% 200%);
  clip-path: polygon(50% 150%, 60% 150%, 60% 200%);
  transform-origin: 39% 121%;
  -webkit-animation: fly72 5000ms 2840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly72 5000ms 2840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(73) {
  color: #6d0edb;
  -webkit-clip-path: polygon(60% 150%, 60% 200%, 70% 200%);
  clip-path: polygon(60% 150%, 60% 200%, 70% 200%);
  transform-origin: 102% 118%;
  -webkit-animation: fly73 5000ms 2880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly73 5000ms 2880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(74) {
  color: #6b0dde;
  -webkit-clip-path: polygon(60% 150%, 70% 150%, 70% 200%);
  clip-path: polygon(60% 150%, 70% 150%, 70% 200%);
  transform-origin: 26% 160%;
  -webkit-animation: fly74 5000ms 2920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly74 5000ms 2920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(75) {
  color: #690de1;
  -webkit-clip-path: polygon(70% 150%, 70% 200%, 80% 200%);
  clip-path: polygon(70% 150%, 70% 200%, 80% 200%);
  transform-origin: 57% 108%;
  -webkit-animation: fly75 5000ms 2960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly75 5000ms 2960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(76) {
  color: #670ce4;
  -webkit-clip-path: polygon(70% 150%, 80% 150%, 80% 200%);
  clip-path: polygon(70% 150%, 80% 150%, 80% 200%);
  transform-origin: 39% 117%;
  -webkit-animation: fly76 5000ms 3000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly76 5000ms 3000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(77) {
  color: #650ce7;
  -webkit-clip-path: polygon(80% 150%, 80% 200%, 90% 200%);
  clip-path: polygon(80% 150%, 80% 200%, 90% 200%);
  transform-origin: 101% 196%;
  -webkit-animation: fly77 5000ms 3040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly77 5000ms 3040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(78) {
  color: #630bea;
  -webkit-clip-path: polygon(80% 150%, 90% 150%, 90% 200%);
  clip-path: polygon(80% 150%, 90% 150%, 90% 200%);
  transform-origin: 33% 196%;
  -webkit-animation: fly78 5000ms 3080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly78 5000ms 3080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(79) {
  color: #610bed;
  -webkit-clip-path: polygon(90% 150%, 90% 200%, 100% 200%);
  clip-path: polygon(90% 150%, 90% 200%, 100% 200%);
  transform-origin: 44% 174%;
  -webkit-animation: fly79 5000ms 3120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly79 5000ms 3120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(80) {
  color: #5f0af0;
  -webkit-clip-path: polygon(90% 150%, 100% 150%, 100% 200%);
  clip-path: polygon(90% 150%, 100% 150%, 100% 200%);
  transform-origin: 46% 132%;
  -webkit-animation: fly80 5000ms 3160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly80 5000ms 3160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(81) {
  color: #5d0af3;
  -webkit-clip-path: polygon(0% 200%, 0% 250%, 10% 250%);
  clip-path: polygon(0% 200%, 0% 250%, 10% 250%);
  transform-origin: 36% 188%;
  -webkit-animation: fly81 5000ms 3200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly81 5000ms 3200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(82) {
  color: #5b09f6;
  -webkit-clip-path: polygon(0% 200%, 10% 200%, 10% 250%);
  clip-path: polygon(0% 200%, 10% 200%, 10% 250%);
  transform-origin: 48% 201%;
  -webkit-animation: fly82 5000ms 3240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly82 5000ms 3240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(83) {
  color: #5909f9;
  -webkit-clip-path: polygon(10% 200%, 10% 250%, 20% 250%);
  clip-path: polygon(10% 200%, 10% 250%, 20% 250%);
  transform-origin: -7% 156%;
  -webkit-animation: fly83 5000ms 3280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly83 5000ms 3280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(84) {
  color: #5708fc;
  -webkit-clip-path: polygon(10% 200%, 20% 200%, 20% 250%);
  clip-path: polygon(10% 200%, 20% 200%, 20% 250%);
  transform-origin: 41% 193%;
  -webkit-animation: fly84 5000ms 3320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly84 5000ms 3320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(85) {
  color: #5508ff;
  -webkit-clip-path: polygon(20% 200%, 20% 250%, 30% 250%);
  clip-path: polygon(20% 200%, 20% 250%, 30% 250%);
  transform-origin: 44% 242%;
  -webkit-animation: fly85 5000ms 3360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly85 5000ms 3360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(86) {
  color: #5307ff;
  -webkit-clip-path: polygon(20% 200%, 30% 200%, 30% 250%);
  clip-path: polygon(20% 200%, 30% 200%, 30% 250%);
  transform-origin: 27% 185%;
  -webkit-animation: fly86 5000ms 3400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly86 5000ms 3400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(87) {
  color: #5107ff;
  -webkit-clip-path: polygon(30% 200%, 30% 250%, 40% 250%);
  clip-path: polygon(30% 200%, 30% 250%, 40% 250%);
  transform-origin: 1% 195%;
  -webkit-animation: fly87 5000ms 3440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly87 5000ms 3440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(88) {
  color: #4f06ff;
  -webkit-clip-path: polygon(30% 200%, 40% 200%, 40% 250%);
  clip-path: polygon(30% 200%, 40% 200%, 40% 250%);
  transform-origin: 19% 197%;
  -webkit-animation: fly88 5000ms 3480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly88 5000ms 3480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(89) {
  color: #4d06ff;
  -webkit-clip-path: polygon(40% 200%, 40% 250%, 50% 250%);
  clip-path: polygon(40% 200%, 40% 250%, 50% 250%);
  transform-origin: 47% 248%;
  -webkit-animation: fly89 5000ms 3520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly89 5000ms 3520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(90) {
  color: #4b05ff;
  -webkit-clip-path: polygon(40% 200%, 50% 200%, 50% 250%);
  clip-path: polygon(40% 200%, 50% 200%, 50% 250%);
  transform-origin: 66% 238%;
  -webkit-animation: fly90 5000ms 3560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly90 5000ms 3560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(91) {
  color: #4905ff;
  -webkit-clip-path: polygon(50% 200%, 50% 250%, 60% 250%);
  clip-path: polygon(50% 200%, 50% 250%, 60% 250%);
  transform-origin: 88% 151%;
  -webkit-animation: fly91 5000ms 3600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly91 5000ms 3600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(92) {
  color: #4704ff;
  -webkit-clip-path: polygon(50% 200%, 60% 200%, 60% 250%);
  clip-path: polygon(50% 200%, 60% 200%, 60% 250%);
  transform-origin: 91% 232%;
  -webkit-animation: fly92 5000ms 3640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly92 5000ms 3640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(93) {
  color: #4504ff;
  -webkit-clip-path: polygon(60% 200%, 60% 250%, 70% 250%);
  clip-path: polygon(60% 200%, 60% 250%, 70% 250%);
  transform-origin: 69% 178%;
  -webkit-animation: fly93 5000ms 3680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly93 5000ms 3680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(94) {
  color: #4303ff;
  -webkit-clip-path: polygon(60% 200%, 70% 200%, 70% 250%);
  clip-path: polygon(60% 200%, 70% 200%, 70% 250%);
  transform-origin: 89% 209%;
  -webkit-animation: fly94 5000ms 3720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly94 5000ms 3720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(95) {
  color: #4103ff;
  -webkit-clip-path: polygon(70% 200%, 70% 250%, 80% 250%);
  clip-path: polygon(70% 200%, 70% 250%, 80% 250%);
  transform-origin: 23% 154%;
  -webkit-animation: fly95 5000ms 3760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly95 5000ms 3760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(96) {
  color: #3f02ff;
  -webkit-clip-path: polygon(70% 200%, 80% 200%, 80% 250%);
  clip-path: polygon(70% 200%, 80% 200%, 80% 250%);
  transform-origin: 40% 193%;
  -webkit-animation: fly96 5000ms 3800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly96 5000ms 3800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(97) {
  color: #3d02ff;
  -webkit-clip-path: polygon(80% 200%, 80% 250%, 90% 250%);
  clip-path: polygon(80% 200%, 80% 250%, 90% 250%);
  transform-origin: 37% 182%;
  -webkit-animation: fly97 5000ms 3840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly97 5000ms 3840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(98) {
  color: #3b01ff;
  -webkit-clip-path: polygon(80% 200%, 90% 200%, 90% 250%);
  clip-path: polygon(80% 200%, 90% 200%, 90% 250%);
  transform-origin: 31% 203%;
  -webkit-animation: fly98 5000ms 3880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly98 5000ms 3880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(99) {
  color: #3901ff;
  -webkit-clip-path: polygon(90% 200%, 90% 250%, 100% 250%);
  clip-path: polygon(90% 200%, 90% 250%, 100% 250%);
  transform-origin: 122% 238%;
  -webkit-animation: fly99 5000ms 3920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly99 5000ms 3920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(100) {
  color: #3700ff;
  -webkit-clip-path: polygon(90% 200%, 100% 200%, 100% 250%);
  clip-path: polygon(90% 200%, 100% 200%, 100% 250%);
  transform-origin: 126% 174%;
  -webkit-animation: fly100 5000ms 3960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly100 5000ms 3960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}

.ui .load-text:nth-child(101) {
  color: #3500ff;
  -webkit-clip-path: polygon(0% 250%, 0% 300%, 10% 300%);
  clip-path: polygon(0% 250%, 0% 300%, 10% 300%);
  transform-origin: 49% 228%;
  -webkit-animation: fly101 5000ms 4000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
  animation: fly101 5000ms 4000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
}
