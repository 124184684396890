//
// Scroll mixins
//

@mixin scrollbar-color($color, $hover-color: null) {
    scrollbar-color: $color transparent;

    &::-webkit-scrollbar-thumb {
        background-color: $color;
    }

    @if ($hover-color != null) {
        &:hover {
            scrollbar-color: $hover-color transparent;

            &::-webkit-scrollbar-thumb {
                background-color: $hover-color;
            }
        }
    }
}
